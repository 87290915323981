.copyable {
  display: flex;
  align-items: center;
}

.copyable__button:hover {
  opacity: 1;
}

.copyable__button,
.copyable__copied {
  display: flex;
  height: 16px;
  padding-right: 0;
  background: none;
}

.copyable__button--icon,
.copyable__copied--icon {
  height: 16px !important;
  width: 16px !important;
  color: var(--text-1);
}

.copyable__button {
  opacity: 0.3;
  color: var(--text-2);
}

.copyable__copied {
  /* color: #0648d7; */
  cursor: auto;
}

.copyable__opaque {
  opacity: 1;
  padding: 0;
}
