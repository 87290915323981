body {
  overflow-x: hidden;
  background-color: var(--gradient-base-color-2);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Sancreek" "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* custom scrollbar */
/* This will change the width of the scrollbar */
.windows ::-webkit-scrollbar {
  width: 8px;
  height: 2px;
}

/* This will change the background color of the scrollbar track*/
.windows ::-webkit-scrollbar-track {
  background: transparent;
}
.windows ::-webkit-scrollbar-corner {
  background: transparent;
}

/* This will change the color of the scrollbar thumb (the element that you grab and move)*/
.windows ::-webkit-scrollbar-thumb {
  background: var(--text-2);
  opacity: 0.4;
  border-radius: 100px;
}

.windows ::-webkit-scrollbar-thumb:hover {
  background: var(--text-3);
  opacity: 0.6;
}

#screener-listbox {
  background-color: var(--background);
}

.MuiDrawer-paper {
  background-color: var(--background) !important;
  color: var(--text-1) !important;
}

.MuiAutocomplete-popper > .MuiPaper-elevation {
  background-color: var(--navBg) !important;
}

.MuiInputAdornment-root {
  color: var(--text-2) !important;
}

.MuiDrawer-root {
  z-index: 7 !important;
}

:root {
  --notify-onboard-container-position-top: 40px;
  --account-center-z-index: 100;
}
