.divider {
  width: 100%;
  border-bottom: 1px solid var(--border-dark);
  box-sizing: border-box;
  display: flex;
}

.infoBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 20px;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  font-family: "Work Sans";
}
.infoBoxHeader {
  font-family: "Work Sans";
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: var(--text-2);
}
.infoBoxContent {
  color: var(--text-1);
  font-weight: 400;
  font-size: 15px;
}

.table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  width: 100%;
}

.table .tableList {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
  height: 24px;
  margin-bottom: 8px;
}

.table .tableRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  gap: 16px;
  height: 20px;
  cursor: pointer;
}
.tableRow:not(:first-of-type) {
  margin-bottom: 16px;
}
.table .tableHeader {
  font-size: 10px;
  color: var(--text-2);
  letter-spacing: 0.08em;
  border-bottom: 1px solid var(--border-dark);
  margin-bottom: 16px;
}

.table .tableRow .tableCell {
  padding: 10px 0;
  color: var(--text-1);
}
.tableCell:nth-of-type(1) {
  flex-basis: 17.3%;
}
.tableCell:nth-of-type(2) {
  flex-basis: 32%;
}
.tableCell:nth-of-type(3) {
  flex-basis: 9.8%;
}
.tableCell:nth-of-type(4) {
  flex-basis: 16.6%;
}
.tableCell:nth-of-type(5) {
  flex-grow: 1;
}
.alignRight {
  text-align: right;
}
.alignLeft {
  text-align: left;
}

.verifiedContainer {
  display: inline-block;
  padding: 1px 6px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  /* identical to box height, or 15px */

  text-align: right;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Success */

  color: var(--success);
  border: 1px solid var(--success);
  border-radius: 16px;
}

.column {
  display: flex;
  flex-direction: column;
}

.typeTag {
  display: flex;
  padding: 1px 8px;
  background: var(--text-3);
  border-radius: 24px;
  align-items: center;
  display: flex; /* Added */
  justify-content: center; /* Added */
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* identical to box height, or 16px */

  letter-spacing: -0.02em;

  /* Base */

  color: var(--base);
}

.text {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* identical to box height, or 20px */

  letter-spacing: -0.02em;
  word-wrap: break-word;

  /* Text I */

  color: var(--text-1);
}

.addressRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.crossIcon {
  margin-right: 8px;
  margin-bottom: 16px;
  cursor: pointer;
}

.iconButtonText {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: -0.02em;
}

.checkbox {
  position: relative;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 1px;
  background-color: var(--text-1);
  outline: var(--primary-color);
  transition: background-color 0.2s ease-in-out;
}

.checkbox:checked {
  background-color: var(--primary-color);
  border: 1px solid var(--text-3);
}
.checkbox:focus {
  outline: none;
  box-shadow: 0 0 3px var(--primary-color); /* Optional: focus outline */
}

.checkbox:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 8px;
  border: solid #000; /* Change the color of the tick here */
  border-width: 0 1px 1px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.arrow_back_icon {
  cursor: pointer;
  color: var(--text-1);
}

.cursorDiv {
  cursor: pointer;
}

.loadingHoverCard {
  border-radius: 6px;
  width: max-content;
  padding: 16px;
  min-height: 180px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.circularProgress {
  color: var(--primary-color);
}

.circularProgressWhite {
  color: #ffffff;
}
.buttonImage {
  height: 24px;
  width: 24px;
}

.avatar {
  margin-top: 4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
