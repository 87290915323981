.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyStateBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  text-align: center;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  /* identical to box height, or 17px */

  text-align: center;
  letter-spacing: normal;

  margin-top: 10px;

  /* Text II */

  color: var(--text-2);
}
