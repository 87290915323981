.refetch {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  gap: 8px;
  height: 100%;
}

.refresh_icon {
  height: 24px;
  width: 24px;
  color: var(--secondary-color);
}
